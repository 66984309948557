/*! 2025-2-14 17:56:46 */
[data-v-1a38bae7]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}div[data-v-1a38bae7]{display:flex;align-items:flex-start;justify-content:'center';flex-direction:column}div p[data-v-1a38bae7]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:160px}div .seperator[data-v-1a38bae7]{margin:0 5px}

[data-v-49f0b6e0]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}[data-v-49f0b6e0] .el-dialog__header{text-align:left}.line-one[data-v-49f0b6e0]{width:125px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.packing-detail-wrapper[data-v-49f0b6e0]{display:flex;align-items:flex-end}.packing-detail-wrapper .content[data-v-49f0b6e0]{flex:1;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-right:5px}.packing-detail-wrapper .item[data-v-49f0b6e0]{width:20px}.el-icon-edit[data-v-49f0b6e0]{cursor:pointer;color:#409eff;cursor:'pointer'}


input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-v-d22db8e0]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.popover-title[data-v-d22db8e0]{display:inline-block;width:16px;height:16px;line-height:16px;text-align:center;font-size:13px;margin-left:5px;position:absolute;bottom:10px;right:10px}.popover-title[data-v-d22db8e0]:hover{cursor:pointer}.green-box[data-v-d22db8e0]{background:rgba(112,182,3,0.2);color:#70b603}.origin-box[data-v-d22db8e0]{background:rgba(245,154,35,0.2);color:#f59a23}

[data-v-792d0d4e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.dialog-fixed[data-v-792d0d4e] .el-dialog__header{border-bottom:1px solid #d8d8d8}.dialog-fixed[data-v-792d0d4e] .el-dialog__footer{border-top:1px solid #d8d8d8;padding-top:20px}.dialog-content[data-v-792d0d4e]{height:100%;overflow-y:auto}.dialog-content p[data-v-792d0d4e],.dialog-content span[data-v-792d0d4e]{color:#666666}.dialog-content p[data-v-792d0d4e]{margin-left:85px;margin-right:10px;word-break:break-word}


/*# sourceMappingURL=chunk-03a920e8.c000a06d.css.map*/