@import "~@/erp/styles/variables/variables.scss";









































































































.popover-title {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  margin-left: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.popover-title:hover {
  cursor: pointer;
}
.green-box {
  background: rgba(112, 182, 3, 0.2);
  color: #70b603;
}
.origin-box {
  background: rgba(245, 154, 35, 0.2);
  color: #f59a23;
}
