@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































.dialog-fixed {
  /deep/ {
    .el-dialog__header {
      border-bottom: 1px solid #d8d8d8;
    }
    .el-dialog__footer {
      border-top: 1px solid #d8d8d8;
      padding-top: 20px;
    }
  }
}

.dialog-content {
  height: 100%;
  overflow-y: auto;
  p,
  span {
    color: #666666;
  }
  p {
    margin-left: 85px;
    margin-right: 10px;
    word-break: break-word;
  }
}
