@import "~@/erp/styles/variables/variables.scss";





































div {
  display: flex;
  align-items: flex-start;
  justify-content: 'center';
  flex-direction: column;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }

  .seperator {
    margin: 0 5px;
  }
}
